import React, { useState } from "react";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../components/TabPanel/index.jsx";
import useStyles from "./styles.js";

import { useAbility } from '@casl/react';
import { AbilityContext } from '../../utils/authentication';
import DefaultShiftsTable from "../../components/Tables/Entities/default_shifts.jsx";
import OperatorsRegistryTable from "../../components/Tables/Entities/operators_registry.jsx";


export default function DataEntryCustom(props) {
  const classes = useStyles();
  const [ currentPage, setCurrentPage ] = useState(0);
  const ability = useAbility(AbilityContext);

  const TABS = [
    {
      title: 'Turni',
      entity: 'default_shift',
      component: <DefaultShiftsTable />
    },
    {
      title: 'Anagrafica operatori',
      entity: 'operators_registry',
      component: <OperatorsRegistryTable />
    },
  ];

  const handleChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <AppBar 
        position="static"
      >
        <Tabs value={currentPage} onChange={handleChange}>
          {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
            <Tab label={t.title} key={i} />
          ))}
        </Tabs>
      </AppBar>
      {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
        <TabPanel value={currentPage} index={i} key={i}>
          {t.component}
        </TabPanel>
      ))}
    </Grid>
  );
}
