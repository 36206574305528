import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi, postApi } from "../../../utils/api";
import CanTable from "../CanTable";
import moment from 'moment';


const ENTITY = "default_shift";
const ENDPOINT = "DefaultShifts";
const TITLE = "Turni";
const COLUMNS = [
  { title: 'Giorno', field: 'des_dow', type: 'string',
    lookup: {
    0: 'Lunedì',
    1: 'Martedì',
    2: 'Mercoledì',
    3: 'Giovedì',
    4: 'Venerdì',
    5: 'Sabato',
    6: 'Domenica',
    }
  },
  { title: 'Codice turno', field: 'cod_shift', type: 'string'},
  { title: 'Inizio turno', field: 'tm_start_shift', type: 'time'},
  { title: 'Fine turno', field: 'tm_end_shift', type: 'time'},
  { title: 'Operatori produzione', field: 'num_operators_production', type: 'numeric'},
  { title: 'Operatori preparazione', field: 'num_operators_setup', type: 'numeric'},
];
const ID_COLUMN = "id";

const getData = async () => await(getApi(`${ENDPOINT}_list/`));
const createData = async (record) => await postApi(`${ENDPOINT}/`, record);
const updateData = async (record) => await patchApi(`${ENDPOINT}/${record.id}`, record);
const deleteData = async (record) => await deleteApi(`${ENDPOINT}/${record.id}`, record);

export default function DefaultShiftsTable(props) {
  const [data, setData] = useState([]);
  
  const onRowAdd = async (newData) => {

    COLUMNS.filter(x => x['type'] === 'time')
      .map(x => x['field'])
      .forEach(x => {
        let fixedDate = new Date(newData[x].setSeconds(0));
        newData[x] = fixedDate.toLocaleTimeString();
      });

    try {
      newData = await createData(newData);
      setData([ ...data, newData ]);
    } catch (err) {
      console.error(err);
    }
  }

  const onRowUpdate = async (newData, oldData) => {
    COLUMNS.filter(x => x['type'] === 'time')
      .map(x => x['field'])
      .forEach(x => {
        let fixedDate = new Date(newData[x].setSeconds(0));
        newData[x] = fixedDate.toLocaleTimeString();
      });

    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  useEffect(() => (async () => {
    const response = await getData();
    const responseData = response.data;

    const timeFieldNames = COLUMNS.filter(x => x['type'] === 'time')
      .map(x => x['field']);  

      timeFieldNames.forEach(x => {
      responseData.forEach((row) => {
        let fixedDate = new moment(row[x], 'HH:mm:ss');
        row[x] = fixedDate.toDate();
      })
    });
      
    setData(responseData);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}