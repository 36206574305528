import React, { useState } from "react";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../components/TabPanel/index.jsx";
import useStyles from "./styles.js";

import { useAbility } from '@casl/react';
import { AbilityContext } from '../../utils/authentication';

import ScheduledOrdersTable from "../../components/Tables/Entities/scheduled_orders";

export default function DataEntry(props) {
  const classes = useStyles();
  const [ currentPage, setCurrentPage ] = useState(0);
  const ability = useAbility(AbilityContext);
  const { runId } = props.match.params;

  const TABS = [
    {
      title: 'Ordini schedulati',
      entity: 'run',
      component: <ScheduledOrdersTable runId={runId} />
    }
  ];

  const handleChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <AppBar 
        position="static"
      >
        <Tabs
          value={currentPage}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
            <Tab label={t.title} key={i} />
          ))}
        </Tabs>
      </AppBar>
      {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
        <TabPanel value={currentPage} index={i} key={i}>
          {t.component}
        </TabPanel>
      ))}
    </Grid>
  );
}
