import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { getApi, patchApi } from "../../../utils/api";
import { MTableAction } from '@material-table/core';
import CanTable from "../CanTable";
import _ from "lodash";

const ENTITY = "run";
const ENDPOINT = "runs";
const TITLE = "Run";
const COLUMNS = [
  { title: 'Id', field: 'id', type: 'numeric', editable: 'never', defaultSort: 'desc' },
  { title: 'Titolo', field: 'title', type: 'string'},
  { title: 'Descrizione', field: 'description', type: 'string'},
  { title: 'Data', field: 'date', type: 'date', editable: 'never'},
  { title: 'Stato', field: 'status', type: 'string', editable: 'never' },
  { title: 'Default run', field: 'flg_default_run', type: 'boolean' },
  { title: 'Utente', field: 'user_id', type: 'numeric', editable: 'never'}
];
const ID_COLUMN = "id";

const getData = async () => await(getApi(`${ENDPOINT}/`));
const updateData = async (record) => await patchApi(`${ENDPOINT}/${record.id}`, record);

export default function RunTable(props) {
  const [data, setData] = useState([]);
  const history = useHistory();

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      actions={[{
        icon: "launch",
        tooltip: "Dettagli",
        onClick: (event, rowData) => history.push(`/runs/${rowData.id}`)
      },
      {
        icon: "request_page",
        tooltip: "Ordini",
        onClick: (event, rowData) => history.push(`/runs/${rowData.id}/scheduled_orders`)
      }]}
      editable={{
        onRowUpdate
      }}
      components={{
        Action: props => {
          if (_.isFunction(props.action)) {
            return ( <MTableAction {...props} /> );
          }
          const action = props.action;

          return (
            <Button
              onClick={(event) => props.action.onClick(event, props.data)}
              color="primary"
              variant="outlined"
              style={{textTransform: 'none'}}
              size="small"
              style={{ margin: '1px' }}
            >
              {action?.tooltip}
            </Button>
          );
        },
      }}
    />
  );
}