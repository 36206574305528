import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi, postApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "TransferTimes";
// you might want to manually translate TITLE to Italian
const TITLE = "Tempo di movimentazione";
const COLUMNS = [
  { title: 'Codice stabilimento partenza', field: 'cod_establishment_from', type: 'numeric', editable: 'never' },
  { title: 'Codice stabilimento destinazione', field: 'cod_establishment_to', type: 'numeric', editable: 'never' },
  { title: 'Tempo di movimentazione', field: 'time_transfer', type: 'numeric', editable: 'always' },
  { title: 'Ritardo entrata', field: 'delay_entrata', type: 'numeric', editable: 'always' },
  { title: 'Ritardo uscita', field: 'delay_uscita', type: 'numeric', editable: 'always' }
];

const ID_COLUMN = "id"

const getData = async () => await(getApi(`TransferTimes/`));
const createData = async (record) => await postApi(`TransferTimes/`, record);
const updateData = async (record) => await patchApi(`TransferTimes/${record.id}`, record);
const deleteData = async (record) => await deleteApi(`TransferTimes/${record.id}`, record);

export default function TransferTimesTable(props) {
  const [data, setData] = useState([]);
  
  const onRowAdd = async (newData) => {

    try {
      newData = await createData(newData);
      setData([ ...data, newData ]);
    } catch (err) {
      console.error(err);
    }
  }

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}
