import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { AbilityContext, defaultAbility, isUserAuthenticated } from './utils/authentication';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import AppNavigation from "./components/AppNavigation";
import CanRoute from './components/AppNavigation/CanRoute';
import custom from "./custom";

import Login from "./containers/Login";
// import Home from "./containers/Home";
import RunList from "./containers/RunList";
import RunDetail from "./containers/RunDetail";
import RunOrders from "./containers/RunOrders";
import SyncinOrders from "./containers/SyncinOrders";
import RunAdd from "./containers/RunAdd";
import DataEntry from "./containers/DataEntry";
import CurrentGantt from "./containers/CurrentGantt";
import DataEntryCustom from "./containers/DataEntryCustom";
import KPICustom from "./containers/KPICustom";


function App() {
  useEffect(() => {
    document.title = custom.app_title
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AbilityContext.Provider value={defaultAbility}>
        <Switch>
          <Route
            exact
            path="/login"
            render={() => {
            if (isUserAuthenticated()) {
              return (
                <Redirect to="/" />
              );
            }

            return <Login />;
          }} />
          
          <Route>
            <AppNavigation />
            <Switch>
              {/* <CanRoute exact path="/" component={Home}/> */}
              <CanRoute exact path="/">
                <Redirect to="/current_gantt" />
              </CanRoute>
              <CanRoute exact path="/runs" I="read" a="run" component={RunList}/>
              <CanRoute exact path="/current_gantt" I="read" a="current_gantt" component={CurrentGantt}/>
              <CanRoute exact path="/runs/new" I="create" a="run" component={RunAdd}/>
              <CanRoute exact path="/runs/new_simulated" I="create" a="run_simulated" component={() => (<RunAdd simulated />)} />
              <CanRoute exact path="/runs/:runId" I="read" a="run" component={RunDetail}/>
              <CanRoute exact path="/runs/:runId/scheduled_orders" I="read" a="run" component={RunOrders}/>
              <CanRoute exact path="/syncin_orders" I="read" a="run" component={SyncinOrders}/>
              <CanRoute exact path="/data" component={DataEntry}/>
              <CanRoute exact path="/data_custom" component={DataEntryCustom}/>
              <CanRoute exact path="/kpi" component={KPICustom}/>
              <CanRoute>
                <Redirect to="/" />
              </CanRoute>
            </Switch>
          </Route>
        </Switch>
      </AbilityContext.Provider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
