import React, { useState } from "react";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../components/TabPanel/index.jsx";
import useStyles from "./styles.js";

import { useAbility } from '@casl/react';
import { AbilityContext } from '../../utils/authentication';
import MachinesStopsTable from "../../components/Tables/Entities/machines_stops.jsx";
import OperatorsStopsTable from "../../components/Tables/Entities/operators_stops.jsx";
import EquipmentsTable from "../../components/Tables/Entities/equipments.jsx";
import SimulatedOrdersTable from "../../components/Tables/Entities/simulated_orders.jsx";
import PurchaseOrdersTable from "../../components/Tables/Entities/PurchaseOrders.jsx";
import TransferFrequencyTable from "../../components/Tables/Entities/TransferFrequency.jsx";
import TransferTimesTable from "../../components/Tables/Entities/TransferTimes.jsx";


export default function DataEntry(props) {
  const classes = useStyles();
  const [ currentPage, setCurrentPage ] = useState(0);
  const ability = useAbility(AbilityContext);

  const TABS = [
    {
      title: 'Fermi macchina',
      entity: 'machines_stop',
      component: <MachinesStopsTable />
    },
    {
      title: 'Fermi operatori',
      entity: 'operators_stop',
      component: <OperatorsStopsTable />
    },
    {
      title: 'Attrezzature',
      entity: 'equipments',
      component: <EquipmentsTable />
    },
    {
      title: 'Ordini simulati',
      entity: 'simulated_order',
      component: <SimulatedOrdersTable />
    },
    {
      title: 'Ordini commerciali',
      entity: 'PurchaseOrders',
      component: <PurchaseOrdersTable />
    },
    {
      title: 'Frequenze di movimentazione',
      entity: 'TransferFrequency',
      component: <TransferFrequencyTable />
    },
    {
      title: 'Tempo di movimentazione',
      entity: 'TransferTimes',
      component: <TransferTimesTable />
    },
  ];

  const handleChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <AppBar 
        position="static"
      >
        <Tabs value={currentPage} onChange={handleChange}>
          {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
            <Tab label={t.title} key={i} />
          ))}
        </Tabs>
      </AppBar>
      {TABS.filter(t => ability.can('read', t.entity)).map((t, i) => (
        <TabPanel value={currentPage} index={i} key={i}>
          {t.component}
        </TabPanel>
      ))}
    </Grid>
  );
}
