import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import _ from "lodash";
import { Grid, Paper, TextField } from "@material-ui/core";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from 'moment';

import useStyles from "./styles.js";
import { getApi } from "../../utils/api/index.js";

import materialColorHash from "material-color-hash";
import GanttBlocksTable from "../Tables/Entities/current_gantt_blocks.jsx";

import DESCRIPTIONS from '../../assets/table_descriptions.json';

am4core.useTheme(am4themes_animated);


const TYPE = "cod_type_interval";
const MACHINE = "yrifreparto_0";
const START = "tms_start";
const END = "tms_end";
const DEADLINE = "dat_deadline";
const CUSTOMER = "bpcord_0";
const LABEL = "mfgnum_0";
const RANK = "val_rank";
const COLOR = "bpcord_0";
const PRODUCT = "itmref_0";
const QUANTITY = "extqty_0";

const BLACKLIST = [
  TYPE, RANK,
  'color', 'text_color', 'border_width', 'border_color', 'id_color', 'tableData'
];

const BOOL_INFO_INTERVALS = [];

const BASE_HEIGHT = 30;
const ROW_HEIGHT = 30;
const MIN_HEIGHT = 600;

const getInfoIntervalKeys = () => {
  return Object.keys(DESCRIPTIONS["InfoCurrentIntervals"])
}

const getInfoIntervalDescription = (key) => {
  return DESCRIPTIONS?.["InfoCurrentIntervals"]?.[key] || key;
}

const AnagramGantt = forwardRef(({ title, data, onSelect, showTable }, ref) => {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedBlockInfo, setSelectedBlockInfo] = useState(null);

  const chartRef = useRef(null);
  let colorSet = new am4core.ColorSet();

  const filterChangeHandler = (event) => {
    const filter = event.target.value;

    setFilter(filter);
  };

  const getFilteredData = () => {
    return data
      .filter((t) =>
        _.values(t).some((v) =>
          String(v).toUpperCase().includes(filter.toUpperCase())
        )
      );
  };

  const buildData = () => {
    return getFilteredData().map(d => {
      const style = materialColorHash(`${d[COLOR]}`);
      d.color = style.backgroundColor;
      d.text_color = style.color;

      // verifica se è in ritardo
      if ((d[DEADLINE] !== null) & (new Date(d[END]).getTime() > new Date(d[DEADLINE]).getTime())) {
        d.border_width = "3";
        d.border_color = "#333333";
      }

      if (d[TYPE] === 'initial_setup') {
        d.tooltip = "always";
        d.HTMLtooltip = "Setup";
        d.orientation = "down";
      }

      if (d[TYPE] === 'chamging_mold') {
        d.tooltip = "always";
        d.HTMLtooltip = "Cambio Stampo";
        d.orientation = "down";
      }

      return d;
    });
  }

  const computeGanttHeight = () => {
    const ganttMachinesNumber = _(getFilteredData()).groupBy(MACHINE).size();
    return Math.max(MIN_HEIGHT, BASE_HEIGHT + ROW_HEIGHT * ganttMachinesNumber);
  };

  useImperativeHandle(ref, () => ({
    setDateRange([startDate, endDate]) {
      if (!startDate || !endDate) {
        return;
      }
      chartRef.current.xAxes.getIndex(0).zoomToDates(
        startDate.toDate(), endDate.toDate()
      );
    }
  }));

  useEffect(() => {
    const chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.paddingRight = 30;
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";

    colorSet.saturation = 0.3;

    chart.data = buildData().sort((a, b) => (a.machine_order > b.machine_order) ? 1 : -1);

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = MACHINE;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 100;
    dateAxis.baseInterval = { count: 15, timeUnit: "minute" };
    dateAxis.renderer.tooltipLocation = 0;

    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.height = am4core.percent(70);
    series1.columns.template.tooltipHTML = `
      <div class="container">
        <div class="row row-cols-2">
          <div class="col">
            <strong>Cliente: </strong>{${CUSTOMER}}<br />
            <strong>Prodotto: </strong>{${PRODUCT}}<br />
            <strong>Prodotto: </strong>{${QUANTITY}}
          </div>
        </div>
      </div>`;
    series1.columns.template.propertyFields.tooltipHTML = 'HTMLtooltip';

    series1.dataFields.openDateX = START;
    series1.dataFields.dateX = END;
    series1.dataFields.categoryY = MACHINE;
    series1.columns.template.opacity = 0.7;
    series1.columns.template.propertyFields.fill = "color"; // get color of column from data
    series1.columns.template.propertyFields.stroke = "border_color"; //get border color from data
    series1.columns.template.strokeWidth = 0;
    series1.columns.template.propertyFields.strokeWidth = "border_width"; //get border size from data
    series1.columns.template.strokeOpacity = 1;
    series1.columns.template.togglable = true;
    series1.columns.template.strokeDasharray = "0.0";
    series1.columns.template.fillOpacity = 0.7;
    series1.tooltip.propertyFields.pointerOrientation = "orientation";
    series1.columns.template.propertyFields.showTooltipOn = "tooltip";
    series1.tooltip.label.interactionsEnabled = true;
    series1.tooltip.events.on("doublehit", (event) => {
      dateAxis.zoomToDates(event.target.dataItem.dates.openDateX, event.target.dataItem.dates.dateX);
    });

    //Write inside task/ordine
    const label = series1.columns.template.createChild(am4core.Label);
    // label.x = am4core.percent(50);
    // label.horizontalCenter = "middle";
    label.html = `<div>{${LABEL}}</div>`;
    label.valign = "middle";
    label.strokeWidth = 0;
    label.align = "center";
    label.truncate = true;
    label.propertyFields.fill = "text_color"; // get color of text from data

    //Set Active status when click on Order And Growing Border Size and set Border Color
    const tActiveState = series1.columns.template.states.create("active");
    // tActiveState.properties.strokeDasharray = "8";
    // tActiveState.properties.strokeWidth = 4;
    tActiveState.properties.stroke = am4core.color("#000");
    tActiveState.properties.fillOpacity = 1;

    series1.columns.template.events.on("hit", (event) => {
      //Deactivate status to Order that was Active
      series1.columns.each((column) => {
        if (column !== event.target) {
          column.setState("default");
          column.isActive = false;
        }
      });

      const block = event.target._dataItem._dataContext;
      setSelectedBlock(block);

      if (_.isFunction(onSelect)) {
        onSelect(block);
      }
    });

    chart.scrollbarX = new am4core.Scrollbar();

    const today = dateAxis.axisRanges.create();
    today.date = moment().toDate();
    today.endDate = moment().add(1,'d').toDate();
    today.grid.disabled = true;
    today.axisFill.fill = am4core.color("#00475c");
    today.axisFill.fillOpacity = 0.2;

    var bullet = new am4charts.AxisBullet();
    bullet.location = 0.5;
    today.bullet = bullet;

    var triangle = today.bullet.createChild(am4core.Triangle);
    triangle.width = 15;
    triangle.height = 11;
    triangle.fill = am4core.color("#00475c");
    triangle.horizontalCenter = "middle";

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data, filter, onSelect]);

  useEffect(() => {
    setSelectedBlockInfo(null);

    if (!selectedBlock) {
      return;
    }

    (async () => {
      const GanttBlockInfo = getInfoIntervalKeys().reduce((obj, key) => { 
        obj[key] = selectedBlock[key]; 
        return obj 
      }, {});
      setSelectedBlockInfo(GanttBlockInfo);
    })();
  }, [selectedBlock]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={9}>
        <Paper className={classes.paper} style={{ display: !showTable ? "block" : "none" }}>
          <Grid container direction="row" justify="space-between">
            <h3>{title}</h3>
            <TextField
                label="Ricerca"
                onChange={filterChangeHandler}
              />
          </Grid>
          <div id="chartdiv" style={{ width: "100%", height: `${computeGanttHeight()}px` }}></div>
        </Paper>
        <div style={{ display: showTable ? "block" : "none" }}>
          <GanttBlocksTable
            title={title}
            data={buildData()}
            onSelect={(block) => {
              setSelectedBlock(block);

              if (_.isFunction(onSelect)) {
                onSelect(block);
              }
            }}
          />
        </div>
      </Grid>
      {selectedBlockInfo && 
        <Grid item sm={12} md={3}>
          <Paper className={classes.paper_detail}>
            {
              _.entries(selectedBlockInfo)
                .filter(([key]) => !BLACKLIST.includes(key))
                .map(([key, value]) => (
                  <Grid item sm={12} key={key}>
                    <span><b>{getInfoIntervalDescription(key)}</b>: {
                      BOOL_INFO_INTERVALS.includes(key) ?
                        ( value ? 'SI' : 'NO' ) :
                        ( _.isObject(value) ? JSON.stringify(value) : value )
                    }</span>
                  </Grid>
                ))
            }
          </Paper>
        </Grid>
      }
    </Grid>
  );
});

export default AnagramGantt;