import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    margin: theme.spacing(0, 0),
    width: '100%'
  },
  content: {
    margin: theme.spacing(1, 0),
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  chips: {
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px 2px'
  }
}));