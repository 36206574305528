import React, { useEffect, useState, useRef } from "react";
import AnagramGantt from "../../components/AnagramCurrentGantt";
import { getApi } from "../../utils/api";
import { AppBar, Chip, FormControl, Grid, InputLabel, ListItemIcon, Checkbox, ListItemText, MenuItem, Select, Switch, Tab, Tabs } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import _ from "lodash";
import useStyles from "./styles.js";
import useSwitchStyles from "./switch.styles.js";

const BASE_ENDPOINT = "current_gantt";
const MACHINES_ENDPOINT = "machines";
const GANTT_BLOCKS_ENDPOINT = "gantt_blocks";
const GROUPS_ENDPOINT = "groups";

const START_DATE = "tms_start";
const END_DATE = "tms_end";


const getGroups = async () => await(getApi(`${BASE_ENDPOINT}/${GROUPS_ENDPOINT}`));
const getMachines = async (ganttGroup) => await(getApi(
  `${BASE_ENDPOINT}/${MACHINES_ENDPOINT}`,
  {gantt_group: ganttGroup}
));
const getGanttBlocks = async (ganttGroup, machines) => await(getApi(
  `${BASE_ENDPOINT}/${GANTT_BLOCKS_ENDPOINT}`,
  {gantt_group: ganttGroup, machines}
));


export default function CurrentGantt(props) {
  const classes = useStyles();
  const switchClass = useSwitchStyles();

  const [ganttBlocks, setGanttBlocks] = useState([]);
  const [groups, setGroups] = useState([]);
  const [machines, setMachines] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(0);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedFormMachines, setSelectedFormMachines] = useState([]);
  const [allMachinesSelected, setAllMachinesSelected] = useState(true);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const [filteredGanttBlocks, setFilteredGanttBlocks] = useState([]);

  const [showGanttTable, setShowGanttTable] = useState(false);

  const MenuProps =   {
    PaperProps: {
    style: {
      maxHeight: 250,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu" }

  useEffect(() => (async () => {

    const groups = await getGroups() || [];
    setGroups(groups);
  })(), []);

  useEffect(() => (async () => {
    if (!groups.length) return;

    const machines = await getMachines(groups[selectedGroup]) || [];
    setMachines(machines);
    setSelectedMachines(machines);
    setSelectedFormMachines(machines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })(), [groups, selectedGroup]);

  useEffect(() => (async () => {
    if (selectedMachines.length === 0) {
      setGanttBlocks([]);
      return;
    }

    const ganttBlocks = await getGanttBlocks(groups[selectedGroup], selectedMachines);
    setGanttBlocks(ganttBlocks);

    setDateRange([
      new Date(_.min(ganttBlocks.map(e => e[START_DATE]))),
      new Date(_.max(ganttBlocks.map(e => e[END_DATE])))
    ]);
    setFilteredGanttBlocks(ganttBlocks.filter(b => 
      new Date(b[END_DATE]) >= dateRange[0] &&
      new Date(b[START_DATE]) <= dateRange[1]
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })(), [selectedMachines]);

  useEffect(() => (async () => {
    setFilteredGanttBlocks(ganttBlocks.filter(b => 
      new Date(b[END_DATE]) >= dateRange[0] &&
      new Date(b[START_DATE]) <= dateRange[1]
    ));
  })(), [dateRange]);

  const handleTabChange = (event, newPage) => {
    setSelectedGroup(newPage);
  };

  return (
    <Grid 
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
      spacing={2}
    >
      <AppBar
        position="static"
      >
        <Tabs value={selectedGroup} onChange={handleTabChange}>
          {groups.map((e, i) => (
            <Tab label={e} key={e} />
          ))}
        </Tabs>
      </AppBar>
      <Grid container spacing={2} className={classes.content}>
        <Grid item sm={12} md>
          <FormControl className={classes.formControl}>
            <InputLabel id="machine-select-label">Macchine</InputLabel>
            <Select
              labelId="machine-select-label"
              id="machine-select"
              multiple
              value={selectedFormMachines}
              onChange={(event) => {
                const newValue = event.target.value;
                if (newValue.includes('all') & allMachinesSelected) {
                  setSelectedFormMachines([]);
                  setAllMachinesSelected(false);
                } else if (newValue.includes('all') & !allMachinesSelected) {
                  setSelectedFormMachines(machines);
                  setAllMachinesSelected(true);
                } else {
                  setSelectedFormMachines(newValue);
                  setAllMachinesSelected(false);
                }
              }}
              onClose={() => setSelectedMachines(selectedFormMachines)}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.filter((i, index) => (index < 10)).map((value) => (
                    <Chip key={value} label={value} size="small" className={classes.chip} />
                  ))}
                  {(selected.length > 10)? 
                    <Chip key="dots" label="Altre..." size="small" className={classes.chip} />:
                    <Chip key="select" label="Filtra" size="small" className={classes.chip} />
                  }
                </div>
              )}
              MenuProps={MenuProps}
            >
              {(!allMachinesSelected) ? 
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={allMachinesSelected}
                    indeterminate={
                      selectedFormMachines.length > 0 && selectedFormMachines.length < machines.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" /> 
              </MenuItem>:
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={allMachinesSelected}
                    indeterminate={
                      selectedFormMachines.length > 0 && selectedFormMachines.length < machines.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Deselect All" /> 
              </MenuItem>
              }
              {
                machines.map((e) => (
                    <MenuItem value={e} key={e}>
                      <ListItemIcon>
                        <Checkbox checked={selectedFormMachines.includes(e)} />
                      </ListItemIcon>
                      <ListItemText primary={e} />
                    </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} md={2}>
          <DatePicker
            label="Da"
            value={dateRange[0]}
            onChange={value => setDateRange([value, value <= dateRange[1] ? dateRange[1] : value])}
            animateYearScrolling
            className={classes.formControl}
          />
        </Grid>
        <Grid item sm={6} md={2}>
          <DatePicker
            label="A"
            value={dateRange[1]}
            onChange={value => setDateRange([value >= dateRange[0] ? dateRange[0] : value, value])}
            animateYearScrolling
            className={classes.formControl}
          />
        </Grid>
        <Grid item sm>
          <Switch
            classes={switchClass}
            checked={showGanttTable}
            onChange={e => setShowGanttTable(e.target.checked)}
          />
        </Grid>
        <Grid item xs={12}>
          <AnagramGantt
            title={`Produzione attuale`}
            data={filteredGanttBlocks}
            showTable={showGanttTable}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
