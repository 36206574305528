import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";

const ENTITY = "customer_order";
const ENDPOINT = "customer_orders";
const TITLE = "Ordini di vendita";
const COLUMNS = [
  { title: 'Id', field: 'rowid', type: 'numeric', editable: 'never'},
  { title: 'Codice ordine', field: 'sohnum_0', type: 'string'},
  { title: 'Codice commessa', field: 'pjt_0', type: 'string'},
  { title: 'Codice prodotto', field: 'itmref_0', type: 'string'},
  { title: 'Quantità', field: 'qty_0', type: 'numeric'},
  { title: 'Stabilimento', field: 'stofcy_0', type: 'string'},
  { title: 'Data richiesta', field: 'orddat_0', type: 'datetime'},
  { title: 'Data richiesta fine', field: 'demdlvdat_0', type: 'datetime'},
  { title: 'Data consegna', field: 'shidat_0', type: 'datetime'},
  { title: 'Codice cliente', field: 'bpcord_0', type: 'string'}
];

const getData = async () => await(getApi(`${ENDPOINT}/`));

export default function CustomerOrdersTable(props) {
  const [data, setData] = useState([]);

  useEffect(() => (async () => {
    const data = await getData();
    setData(data.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}