import React from "react";
import CanTable from "../CanTable";

const ENTITY = "run";
const COLUMNS = [
  { title: 'Codice ordine produzione', field: 'mfgnum_0', type: 'string'},
  { title: 'Codice prodotto', field: 'itmref_0', type: 'string'},
  { title: 'Quantità', field: 'extqty_0', type: 'numeric'},
  { title: 'Inizio', field: 'strdat_0', type: 'datetime'},
  { title: 'Fine', field: 'enddat_0', type: 'datetime'},
  { title: 'Codice macchina', field: 'yrifreparto_0', type: 'string'},
  { title: 'Codice stampo', field: 'equnum_0', type: 'string'},
  { title: 'Codice cliente', field: 'bpcord_0', type: 'string'},
  { title: 'Descrizione cliente', field: 'des_customer', type: 'string'}
];

export default function GanttBlocksTable(props) {
  return (
    <CanTable
      title={props.title}
      entity={ENTITY}
      columns={COLUMNS}
      data={props.data}
      actions={[{
        icon: "launch",
        tooltip: "Seleziona",
        onClick: (event, rowData) => props.onSelect(rowData)
      }]}
    />
  );
}