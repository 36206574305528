import React from "react";
import AnagramKpi from "../../components/AnagramKpi";
import {Grid} from "@material-ui/core";
import useStyles from "../../components/AnagramGantt/styles.js";


export default function KPICustom(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.content}>
      <Grid item sm={12} style={{textAlign: 'center'}}><h1>La tua dashboard custom</h1></Grid>
      <Grid item sm={12} md={6}>
        <AnagramKpi name='Primo KPI extra' value={30}/>
      </Grid>
      <Grid item sm={12} md={6}>
        <AnagramKpi name='Secondo KPI extra' value={70}/>
      </Grid>
      <Grid item sm={12} md={6}>
        <AnagramKpi name='Terzo KPI extra' value={55}/>
      </Grid>
      <Grid item sm={12} md={6}>
        <AnagramKpi name='Quarto KPI extra' value={40}/>
      </Grid>
    </Grid>
  );
}
