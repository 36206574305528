import React from "react";

import { 
  Add,
//   Home,
  PlayCircleFilledRounded,
  StorageRounded
} from '@material-ui/icons';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const menuOptions = [
  /*
  {
    to: "/",
    text: "Home",
    icon: <Home />
  },
  */
  {
    to: "/runs",
    text: "Lista Schedulazioni",
    icon: <PlayCircleFilledRounded />,
    a: "run"
  },
  {
    to: "/runs/new",
    text: "Nuova Schedulazione",
    icon: <Add />,
    I: "create",
    a: "run"
  },
  {
    to: "/runs/new_simulated",
    text: "Nuova Simulazione",
    icon: <Add />,
    I: "create",
    a: "run_simulated"
  },
  {
    to: "/data",
    text: "Data Entry",
    icon: <StorageRounded />
  },
  {
    to: "/syncin_orders",
    text: "Ordini caricati",
    icon: <AccountBalanceWalletIcon />
  },
  // {
  //   to: "/data_custom",
  //   text: "Custom data entry",
  //   icon: <DashboardCustomizeIcon />
  // },
  // {
  //   to: "/kpi",
  //   text: "Custom KPI",
  //   icon: <AnalyticsIcon />
  // },
];

export default menuOptions;
