import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";

const ENTITY = "production_order";
const ENDPOINT = "production_orders";
const TITLE = "Ordini di produzione";
const COLUMNS = [
  { title: 'Id', field: 'rowid', type: 'numeric', editable: 'never'},
  { title: 'Codice ordine', field: 'mfgnum_0', type: 'string'},
  { title: 'Codice commessa', field: 'pjt_0', type: 'string'},
  { title: 'Codice prodotto', field: 'itmref_0', type: 'string'},
  { title: 'Quantità', field: 'uomextqty_0', type: 'numeric'},
  { title: 'Unità di misura', field: 'uom_0', type: 'string'},
  { title: 'Data inizio', field: 'strdat_0', type: 'datetime'},
  { title: 'Data fine', field: 'enddat_0', type: 'datetime'},
  { title: 'Data chiusura', field: 'clodat_0', type: 'datetime'},
  { title: 'Codice cliente', field: 'bpcnum_0', type: 'string'}
];

const getData = async () => await(getApi(`${ENDPOINT}/`));

export default function ProductionOrdersTable(props) {
  const [data, setData] = useState([]);

  useEffect(() => (async () => {
    const data = await getData();
    setData(data.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}