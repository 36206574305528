import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "run";
// you might want to manually translate TITLE to Italian
const TITLE = "Ordini schedulati";
const COLUMNS = [
  { title: 'Run id', field: 'id_run', type: 'numeric', editable: 'never' },
  { title: 'Codice ordine', field: 'cod_order', type: 'string', editable: 'never' },
  { title: 'Anagram id', field: 'id_order_anagram', type: 'numeric', editable: 'never' },
  { title: 'Cliente', field: 'cod_customer', type: 'string', editable: 'never' },
  { title: 'Codice prodotto', field: 'cod_item', type: 'numeric', editable: 'never' },
  { title: 'Quantità', field: 'val_quantity', type: 'numeric', editable: 'never' },
  { title: 'Descrizione', field: 'des_broken', type: 'string', editable: 'never' },
];


const getData = async (runId) => await(getApi(`scheduled_orders/?run_id=${runId}`));

export default function ScheduledOrdersTable(props) {
  const [data, setData] = useState([]);
  const { runId } = props;

  useEffect(() => (async () => {
    if (!runId) return;

    const response = await getData(runId);
    setData(response.data);
  })(), [runId]);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
    />
  );
}
