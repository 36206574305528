import React, { useEffect, useState } from "react";
import { deleteApi, getApi, patchApi, postApi } from "../../../utils/api";
import CanTable from "../CanTable";


const ENTITY = "PurchaseOrders";
// you might want to manually translate TITLE to Italian
const TITLE = "Ordini commerciali";
const COLUMNS = [
  { title: 'Codice ordine vendita', field: 'cod_ordine_vendita', type: 'string', editable: 'always' },
  { title: 'Codice articolo', field: 'cod_articolo', type: 'string', editable: 'always' },
  { title: 'Quantità', field: 'val_qta', type: 'numeric', editable: 'always' },
  { title: 'Codice cliente', field: 'cod_cliente', type: 'string', editable: 'always' },
  { title: 'Data richiesta cliente', field: 'data_richiesta_cliente', type: 'datetime', editable: 'always' }
];

const ID_COLUMN = "id"

const getData = async () => await(getApi(`PurchaseOrders/`));
const createData = async (record) => await postApi(`PurchaseOrders/`, record);
const updateData = async (record) => await patchApi(`PurchaseOrders/${record.id}`, record);
const deleteData = async (record) => await deleteApi(`PurchaseOrders/${record.id}`, record);

export default function PurchaseOrdersTable(props) {
  const [data, setData] = useState([]);
  
  const onRowAdd = async (newData) => {

    try {
      newData = await createData(newData);
      setData([ ...data, newData ]);
    } catch (err) {
      console.error(err);
    }
  }

  const onRowUpdate = async (newData) => {
    newData = await updateData(newData);
    setData(data.map(d => {
      if (d[ID_COLUMN] === newData[ID_COLUMN]) {
        return newData;
      }
      return d;
    }));
  }

  const onRowDelete = async (oldData) => {
    await deleteData(oldData);
    setData(data.filter(d => d[ID_COLUMN] !== oldData[ID_COLUMN]));
  }

  useEffect(() => (async () => {
    const response = await getData();
    setData(response.data);
  })(), []);

  return (
    <CanTable
      title={TITLE}
      entity={ENTITY}
      columns={COLUMNS}
      data={data}
      editable={{
        onRowAdd,
        onRowUpdate,
        onRowDelete,
      }}
    />
  );
}
